const images = {
    mylogo: require('./icons/mylogo-removebg-preview.webp'),
    mylogowhite: require('./icons/mylogo_white-removebg-preview.webp'),
    html: require('./icons/html-5-logotype.webp'),
    css: require('./icons/css-3.webp'),
    javascript: require('./icons/js.webp'),
    reactlogo: require('./icons/atom.webp'),
    bootstrap: require('./icons/bootstrap.webp'),
    tailwindcss: require('./icons/tailwind-logo.webp'),
    django: require('./icons/django-white.webp'),
    python: require('./icons/python.webp'),
    postgre: require('./icons/postgre.webp'),
    seo: require('./icons/google.webp'),
    shopify: require('./icons/shopify.webp'),
    instagram: require('./icons/instagram.webp'),
    github: require('./icons/github.webp'),
    linkedin: require('./icons/linkedin.webp'),
    whatsapp: require('./icons/whatsapp.webp'),
    design: require('./icons/web-design.webp'),
    backend: require('./icons/servers.webp'),
    web: require('./icons/code.webp'),
    cs50w: require('./projects/cs50w.webp'),
    uny: require('./projects/engineering.webp'),
    unity: require('./projects/Unity_logo.webp'),
    background: require('./bg images/pexels-tuesday-temptation-3780104.webp'),
    ecommerce: require('./projects/ecommerce.webp'),
    faketwitter: require('./projects/twitter.webp'),
    fakewhatsapp: require('./projects/wsclone.webp'),
    mail: require('./projects/mail.webp'),
    swissgetal: require('./projects/swissgetal.webp'),
    nurture: require('./projects/nurture.webp'),
    unityLogo: require('./icons/unitylogo.webp'),
    menu: require('./icons/menu.webp'),
    overwatch: require('./icons/overwatch.webp'),
    cod: require('./icons/cod.webp'),
    fromsoftware: require('./icons/fromsoftware.webp'),
    ui: require('./icons/ui.webp'),
    illustrator: require('./icons/illustrator.webp'),
    figma: require('./icons/figma.webp'),
    flutter: require('./icons/flutter-white.webp'),
    ai: require('./icons/ai.webp'),
    engineer: require('./icons/engineer.webp'),
    graduation: require('./icons/graduation-hat.webp'),
    fontawesome: require('./icons/fontawesome.webp'),
    check: require('./icons/check-gif.gif'),
    condimentos: require('./projects/condimentos.webp'),
    design1: require('./icons/casa.png'),
    design2: require('./icons/sushi.png'),
    design3: require('./icons/mypage.png'),


}

export default images